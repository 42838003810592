/* Import all partials here
 * This file gets fed into App.js and loads the styles globally
 */

// Globals
@import "partials/variables";
@import "partials/layout";
@import "partials/inputs";
@import "partials/chart";
@import "partials/buttons";

// Component Styles
@import "partials/navigation";
@import "partials/header";
@import "partials/footer";

.loading-screen {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  &.interior {
    //left: 225px;
  }
  svg path,
  svg rect{
    fill: #1D2C38;
  }
}

.redux-link {
  &:hover {
    cursor: pointer;
  }
}

.fourohfour {
  position: absolute;
  top:0; right:0; bottom:0; left:0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.analysis-slides {
  .form-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .view-element {
      border: none;
      padding: 0;
      margin: 0;
    }
  }
  .view-element {
    .view-element {
      border: none;
      padding: 0;
      margin: 0;
    }
  }
}


// VIEW BLOCK ITEMS
.basic-view-element {
  margin-bottom: 40px;
}

.status-chart {
  position: relative;
  display: flex;
  width: 600px;
  align-items: center;
  justify-content: space-between;
  margin-left: 15px;
  hr {
    height: 1px;
    width: 88%;
    background: #cdcdcd;
    border: none;
    position: absolute;
    top: 10px;
    right: 5%;
  }
  .status {
    padding: 20px 0 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .marker {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      border: 1px solid #cdcdcd;
      background: #ffffff;
      z-index: 2;
      .dot {
        height: 10px;
        width: 10px;
        background: $yellow;
        border-radius: 50%;
        z-index: 3;
      }
    }
    p {
      margin-top: 5px;
      text-transform: capitalize;
      color: #cdcdcd
    }
    &.active {
      .dot { background: $aqua; }
      p { color: #131D27; }
    }
  }
}

.link-button-wrap {
  .btn {
    margin-right: 15px;
    display: inline-flex;
    align-items: center;
    padding-right: 10px;
    svg {
      margin-left: 15px;
    }
  }
}

.text-list {
  margin-top: 10px;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 16px;
  &.normal {
    padding-left: 0;
  }
  &.bulleted {
    list-style-type: disc;
    padding-left: 20px;
  }
  li {
    //text-transform: capitalize;
  }
}

.filter-bar {
  .dynamic-select-box {
    width: 100%;
  }
}


// FOOTER STUFF
.footer-button {
  border-bottom: none;
  border-top: 2px solid #efefef;
  padding-top: 25px;
}


// MISC UTILITY STUFF
.no-padding-left {
  padding-left: 0;
}

.no-border {
  border: none;
}

.two-col {
  display: inline-flex;
  flex-wrap: wrap;
  div {
    padding-right: 40px;
  }
  .text-col {
    margin: 10px 0;
    .fi-label {
      margin-bottom: 0;
      text-transform: capitalize;
    }
    max-width: 48%;
  }
}

.flex {
  display: flex;
  align-items: center;
}

.flex-end {
  justify-content: flex-end;
}

.space-between {
  justify-content: space-between !important;
}

.inline-block { display: inline-block }

h1, h2 {
  font-weight: 700;
}

.redux-link {
  font-family: "Roboto Condensed",sans-serif;
  font-size: 16px;
}


// RESET PASSWORD VIEW STUFF
.set-password {
  background: #F1F3F6;
  display: flex;
  justify-content: center;
  position: absolute;
  top:70px; right: 0; bottom: 0; left: 0;
  padding-top: 90px;
  &.interior {
    top: 0; right: 0; bottom: 0; left: 225px;
  }
  .form-question {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    label {
      margin: 10px 0;
    }
    input {
      margin: 5px 0 5px;
    }
    .error {
      margin-top: 10px;
      color: red;
    }
  }
}


// PROFILE PICTURE
.profile-pic {
  height: 112px;
  width: 112px;
  overflow: hidden;
  border: 1px solid #D8D8D8;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  margin: 15px 0;
  &.small {
    height: 64px;
    width: 64px;
    margin: 0 0 10px 0;
    .profile-img {
      height: 64px;
      width: auto;
    }
  }
  &.header-img {
    height: 56px;
    width: 56px;
    margin: 0 0 15px 0;
    border: none;
    .profile-img {
      height: 56px;
      width: auto;
    }
  }
  .profile-img {
    height: 112px;
    width: auto;
  }
}

// CARD STYLES
.status-active-future {
  background-color: $yellow;
}