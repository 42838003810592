.btn-base {
  padding: 6px 25px;
  margin-left: 10px;
}
.primary-btn {
  @extend .btn-base;
  background: #4EB8C1;
  color: #ffffff;
  border-color: #4EB8C1;
  &:disabled {
    background: #efefef;
    border-color: transparent;
    color: #ababab;
  }
}

.secondary-btn {
  @extend .btn-base;
}

.rounded-btn {
  border-radius: 20px;
}

button {
  &.primary-btn {
    outline: none;
  }
}