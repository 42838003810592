


/* General */

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html, body {
  padding: 0px;
  margin: 0px;
  background-color: #fdfcfa;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

input, textarea {
  outline-color: #70B6BC;
  outline-width:0px !important;
  padding: 4px 12px !important;
  border-radius: 3px !important;
}




.submenu-item a {
  color: #fff;
}

a {
  color: #4A4A4A;
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -ms-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}

a:focus, a:hover {
  color: #4eb8c1;;
  text-decoration: none;
  outline: 0;
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -ms-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}

td a, td a:active, td a:hover {
  color: var(--bluetext);
  text-decoration: none;
}

.float-right {
  float:right;
}

ol {
  font-size: 13px;
  color: var(--darktext);
  padding-left: 15px;
}

input[type="submit"] {
  width: auto !important;
}

input[type=text] {
  outline: 0px solid #3D5052;
}

.label {
  text-align: left;
  padding: 0px;
}

.display-none {
  display: none !important;
}

/*** svgs ****/
.upload-svg, .ki-btn img.upload-svg {
  background-image: url(/images/upload.svg);
  height: 13px;
  width: 15px;
  margin: 0px 5px;
  background-repeat: no-repeat;
  display: inline-block;
  transition: all 300ms;
  -webkit-transition: background 300ms;
  -moz-transition: background 300ms;
  -o-transition: background 300ms;
  transition: background 300ms;
}

.upload-svg.hover{
  background-image: url(/images/upload-white.svg);
}

.nav-dot {
  background-image: url(/images/nav-dot.svg);
  height: 10px;
  width: 10px;
  margin: 0px 5px;
  display: inline-block;
  position: absolute;
  top: -22px;
  border-radius: 50%;
  border: 1px solid #ccc;
  padding: 9px;
  background-repeat: no-repeat;
  background-position: 50%;
  left: 27%;
  background-size: 10px;
}

.nav-dot.active {
  background-image: url(/images/nav-dot-active.svg);
}

.container {
  background: unset;
}

.body-wrap {
  padding-left: 0px;
  padding-right: 40px;
  height: calc(100vh - 70px);
  /* height: 100%; */
  min-height: calc(100vh - 70px);
  padding-top: 30px;
}

.green-body {
  background-color: var(--bluetext);
}

.table-page-heading {
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.phase-options {
  margin-bottom: 60px;
  margin-top: 15px;
}

.phase-options .small-text {
  margin-right: 5%;
}


.first-page-element h1 {
  margin-top: 0px;
}

.content-wrap {
  width: 100%;
  max-width: 900px;
  margin: 0px auto 80px auto;
  /*padding-bottom: 100px;*/
}

.view-element {
  margin-bottom: 40px;
  /* border-bottom: 1px solid #ccc;
  padding-bottom: 40px; */
}

.view-element .heading-3 {
  margin-bottom: 5px;
}

.page-header {
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.page-header h1 {
  width: 65%;
  margin-top: 0px;
}

.page-header .header-buttons {
  width: 30%;
  text-align: right;
}


/* Headings */


.heading-1 {
  font-family:var(--dinbold);
  font-size: 36px;
  color: var(--darktext);
  letter-spacing: -0.25px;
  font-weight: normal;
  margin-bottom: 20px;
  margin-top: 10px;
}

.heading-1-white {
  font-family:var(--dinbold);
  font-size: 36px;
  color: #fff;
  letter-spacing: -0.25px;
  font-weight: normal;
}

.heading-2 {
  font-family:var(--dinbold);
  font-size: 28px;
  color: var(--darktext);
  /* letter-spacing: 1.6px; */
  font-weight: normal;
}


.heading-2-white {
  font-family:var(--dinregular);
  font-size: 24px;
  text-transform: uppercase;
  color: #fff;
  letter-spacing: 1.6px;
  font-weight: normal;
}

.heading-2-blue {
  font-family:var(--dinregular);
  font-size: 24px;
  text-transform: uppercase;
  color: var(--bluetext);
  letter-spacing: 1.6px;
  font-weight: normal;
}

.heading-3, {
  font-family:var(--dinbold);
  font-size: 16px;
  color: var(--darktext);
  font-weight: normal;
}

.form-question .heading-3, .view-element .heading-3  {
  font-family:var(--dinbold);
  font-size: 16px;
  color: var(--darktext);
  font-weight: 700;
}

.heading-3-2 {
  font-family:var(--dinmedium);
  font-size: 12px;
  text-transform: uppercase;
  color: var(--darktext);
  letter-spacing: 1.1px;
  font-weight: normal;
}

.heading-3-white {
  font-family:var(--dinmedium);
  font-size: 12px;
  text-transform: uppercase;
  color: #fff;
  letter-spacing: 1.1px;
  font-weight: normal;
}

.heading-3-blue {
  font-family:var(--dinmedium);
  font-size: 12px;
  text-transform: uppercase;
  color: var(--bluetext);
  letter-spacing: 1.1px;
  font-weight: normal;
}

.heading-3-link {
  font-family:var(--dinmedium);
  font-size: 12px;
  text-transform: uppercase;
  color: var(--bluetext);
  letter-spacing: 1.1px;
  font-weight: normal;
}

.heading-3-nav {
  font-family:var(--dinmedium);
  font-size: 16px;
  text-transform: uppercase;
  color: #fff;
  letter-spacing: 1.1px;
  font-weight: normal;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.heading-3-nav:hover {
  color: var(--bluetext);
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.heading-4 {
  font-family:var(--dinregular);
  font-size: 14px;
  text-transform: uppercase;
  color: var(--bluetext);
  letter-spacing: 1px;
  font-weight: normal;
}

.heading-4-white {
  font-family:var(--dinregular);
  font-size: 14px;
  text-transform: uppercase;
  color: #fff;
  letter-spacing: 1px;
  font-weight: normal;
}

.body-text {
  font-family: "minion-pro",serif;
  font-size: 20px;
  color: var(--darktext);
}

.body-text-white {
  font-family: "minion-pro",serif;
  font-size: 20px;
  color: #fff;
}

.large-text {
  font-family:var(--dinregular);
  font-size: 16px;
  color: var(--darktext);
}

.large-text-transition {
  transition: font-size 500ms;
}

.large-text-transition.active {
  font-size: 16px;
  /* margin: 10px 0px; */
  margin: 5px 0px -5px !important;
}

.margin-transition {
  transition: all 500ms;
}

.margin-transition.active {
  margin: 10px 0px 10px -38px !important;
}

.page-header-transition {
  transition: all 500ms;
}
.page-header-transition.active {
  padding-bottom: 0px!important;
}


/* .margin-transition.active {
  margin: 10px 0px 10px -38px !important;
}

.page-header-transition {
  transition: all 500ms;
}
.page-header-transition.active {
  padding-bottom: 0px!important;
} */


.large-text-white {
  font-family:var(--dinmedium);
  font-size: 16px;
  color: #fff;
}


.small-text {
  font-family:var(--dinregular);
  font-size: 13px;
  color: var(--darktext);
}


.small-text-blue {
  font-family:var(--dinregular);
  font-size: 13px;
  color: var(--bluetext);
}

.caption-text {
  font-family:var(--dinregular);
  font-size: 13px;
  font-style: italic;
  color: #3d5052;
  margin-bottom: 15px;
}

.caption-text-white {
  font-family:var(--dinmedium);
  font-size: 13px;
  font-style: italic;
  color: #fff;
}

/* Header bar */

.header-bar {
  background-color: #202A2B;
  height: 40px;
  padding: 2px 45px;
  border-bottom: 1px solid #C4C4C4;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0,0,0,.3);
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.3);
  z-index: 3;
  position: fixed;
  width: 100%;
}

.logo-block {
  margin-top: 0px;
  margin-bottom: 5px;
}

.breadcrumb {
  background-color: transparent !important;
  /* display: none; */
}

.profile-block {
  text-transform: capitalize;
  position: relative;
  cursor: pointer;
}

.profile-triangle {
  display: inline-block;
  height: 10px;
  width: 20px;
  background-image: url(/images/profile-triangle.svg);
  background-repeat: no-repeat;
  background-position: 50%;
}

.profile-image-header {
  background-color: var(--bluetext);
  border-radius: 50%;
  height: 20px;
  width: 20px;
  display: inline-block;
  position: absolute;
  left: -27px;
  top: 2px;
  background-position: 50%;
  background-size: cover;
}

.profile-submenu {
  position: absolute;
  top: 40px;
  right: 45px;
  width: 220px;
  background-color: #202A2B;
  max-height: 0px;
  overflow: hidden;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.profile-submenu-open {
  max-height: 105px;
  padding: 10px 0px;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.submenu-item {
  padding: 10px 20px;
}

/* Sliding Menu */

.sliding-menu {
  /* background-color: #202A2B; */
  /*height: 100%;*/
  /*position: absolute;*/
  /*width: 224px;*/
  /*left: -44px;*/
  /*top: 40px;*/
  z-index: 5;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  /*position: fixed;*/
  padding: 20px;
  position: fixed;
  width: 225px;
}
.sticky{
  position:fixed;
}

.sliding-menu hr {
  opacity: .45;
}

.open-slide {
  left: 0px !important;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.menu-heading {
  margin-bottom: 20px;
}

.menu-item {
  display: block;
  text-decoration: none !important;
}

/*.menu-item.heading-2-white{*/
/*padding-left: 125px;*/
/*}*/

.menu-item.heading-2-white.selected{
  background-color: var(--bluetext);
  /* margin-left: 115px; */
  padding-left: 125px;
}

a.menu-item.heading-2-white.selected:hover{
  color: #fff;
}

.menu-icon.selected{
  background-color: #fff;
  color: var(--bluetext);
}

.menu-item, .menu-heading {
  /*padding: 20px 0px 15px 25px;*/
  position: relative;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.menu-item:hover {
  color: var(--bluetext);
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.menu-item:hover .menu-icon {
  opacity: 1;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.menu-item:active {
  /* background-color: var(--bluetext); */
  color: #fff;
}

.menu-item:active .menu-icon {
  background-color: #fff;
  color: var(--bluetext);
}

.menu-icon {
  text-transform: capitalize;
  position: absolute;
  right: 10px;
  top: 15px;
  background-color: var(--bluetext);
  color: var(--darktext);
  border-radius: 50%;
  height: 41px;
  width: 41px;
  letter-spacing: -1px;
  text-align: center;
  font-family:var(--dinbold);
  font-weight: normal;
  opacity: .3;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  line-height: 30px;
  box-sizing: border-box;
}

a.menu-item.heading-2-white .menu-icon {
  padding-top: 5px;
}

.selected {
  opacity: 1;
}


.sliding-menu .menu-item img {
  width: 22px;
  position: absolute;
  top: 13px;
  left: 0px;
  height: 20px;
}

/* @media screen and (max-width: 1100px) {
  .sliding-menu .menu-item img {
    left: 19px;
  }
} */


.sliding-menu .menu-item {
  padding-left: 40px;
  position: relative;
}

/* @media screen and (max-width: 1100px) {
  .sliding-menu .menu-item {
    width: 0px;
    overflow: hidden;
    height:48px;
  }
} */

/* Mobile Menu Icon */

#nav-icon3 {
  width: 30px;
  height: 30px;
  position: relative;
  margin: 10px 12px 10px auto;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  cursor: pointer;
}

#nav-icon3 span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: #fff;
  border-radius: 4px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

#nav-icon3 span:nth-child(1) {
  top: 0px;
}

#nav-icon3 span:nth-child(2),
#nav-icon3 span:nth-child(3) {
  top: 9px;
}

#nav-icon3 span:nth-child(4) {
  top: 18px;
}

#nav-icon3.open span:nth-child(1) {
  top: 9px;
  width: 0%;
  left: 50%;
}

#nav-icon3.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#nav-icon3.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#nav-icon3.open span:nth-child(4) {
  top: 9px;
  width: 0%;
  left: 50%;
}

#nav-icon3.arrow span {
  width: 50%;
  top: 5px;
  left: 8px;
}

#nav-icon3.arrow span:nth-child(1) {
  top: 7px;
  width: 0%;
  left: 50%;
}

#nav-icon3.arrow span:nth-child(2) {
  -webkit-transform: rotate(-50deg);
  -moz-transform: rotate(-50deg);
  -o-transform: rotate(-50deg);
  transform: rotate(-50deg);
  margin-top: 10px;
}

#nav-icon3.arrow span:nth-child(3) {
  -webkit-transform: rotate(50deg);
  -moz-transform: rotate(50deg);
  -o-transform: rotate(50deg);
  transform: rotate(50deg);
  margin-top: 0px;
}

#nav-icon3.arrow span:nth-child(4) {
  top: 9px;
  width: 0%;
  left: 50%;
}

/* Button */
.tags-input * {
  outline: none !important;
}

.host .tags .tag-list .tag-item.ng-scope, .tags-input .tags .tag-item {
  outline: none;
  background: unset;
  background-color: #3D50521A !important;
  display: inline-block;
  text-transform: uppercase;
  color:#fff;
  text-decoration: none !important;
  padding: 0px 17px;
  border-radius: 30px;
  border: none;
  color: var(--darktext);
  font-family:var(--dinregular);
  font-size: 14px;
  letter-spacing: 1px;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  height: 34px;
  line-height: 32px;
  outline: none;
}

.form-question .tags-input {
  outline: none;
}

.tags .input {
  min-width: 108px;
  min-height: 32px;
  cursor: pointer;
}

.form-question .tags-input .tags .input {
  /* border: 0; */
  /* outline: 0; */
  /* margin: 2px; */
  /* padding: 0 0 0 5px; */
  /* float: left; */
  /* height: 26px; */
  /* font: 14px "Helvetica Neue",Helvetica,Arial,sans-serif; */
  background-color: none ;
  width: 100px;
}

.form-question tags-input .tags .tag-item .remove-button {
  margin: 0 0 4px 4px;
  padding: 1px 2.5px 5px 3px;
  border: none;
  cursor: pointer;
  vertical-align: middle;
  font-family: var(--dinregular);
  color: #fff;
  background: #3D50524d;
  border-radius: 10px;
  display: inline-block;
  font-size: 22px;
  line-height: 12px;
  text-align: center;
}


.half-date {
  display: inline-block;
  width: 200px;
  margin-right: 20px;
  vertical-align: top;
}

#last-edited {
  /*background-color: #70B6BC;*/
  font-family: var(--dinregular);
  font-size: 13px;
  /*font-style: italic;*/
  border-radius: 15px 0px 0px 15px;
  padding: 7px 25px 7px 15px;
  color: #000;
  margin-top: 10px;
  margin-left: 80%;
}

/*#last-edited:after {*/
/*content: "";*/
/*width: 100%;*/
/*height: 33px;*/
/*background-color: #70B6BC;*/
/*position: absolute;*/
/*top: 10px;*/
/*}*/

#last-edited #saved-time {
  padding-right: 5px;
}


.add-item {
  border-radius: 20px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16), 0 0 1px 0 rgba(0, 0, 0, 0.12);
  border: 0;
}

.add-item:hover {
  border: 0 !important;
}

.ki-btn, .form-wrap input.ki-btn, input#delete {
  display: inline-block;
  text-transform: uppercase;
  color:#fff;
  text-decoration: none !important;
  padding: 6px 17px;
  border-radius: 30px;
  border: 1px solid var(--bluetext);
  color: var(--bluetext);
  font-family:var(--dinmedium);
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1.1px;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  background-color: transparent;
  /* height: 34px; */
  /* line-height: 32px; */
  outline: none;
}

.ki-btn:hover, input#delete:hover {
  color: #fff;
  background-color: var(--bluetext);
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-ou
}

input#delete {
  line-height: initial;
}


.ki-btn:active {
  opacity: .8;
}

.ki-btn.btn-agreement {
  display: block;
  margin: 40px 0px;
}

.form-question .ki-btn .dialog-modal {
  color: #70B6BC;
  font-family: var(--dinregular);
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 1px;
  line-height: 25px;
  text-align: left;
  border: 1px solid #70B6BC;
  border-radius: 18.5px;
  width: 165px;
  height: 34px;
  margin-top: 0px;
}

.ki-btn2 {
  display: inline-block;
  text-transform: uppercase;
  color: #fff;
  background-color: var(--bluetext);
  text-decoration: none;
  border: 1px solid #fff;
  font-family:var(--dinregular);
  font-size: 16px;
  letter-spacing: 1px;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  width: auto !important;
  padding: 0px 17px;
  border-radius: 30px;
  height: 34px;
  outline: none;
}

.ki-btn2:hover {
  background-color: #fff;
  color: var(--bluetext);
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.ki-btn2:active {
  opacity: .8;
}


.study-methods-reference {
  width: 90% !important;
  display: inline-block !important;
  margin: 15px 0px;
  padding: 10px !important;
}

.study-methods-reference + a {
  display: inline-block;
  margin-top:5px;
}

.repeater-field .close-icon {
  margin-right: -20px !important;
  margin-top: 7px !important;
}

.text-btn {
  display: inline-block;
  text-transform: uppercase;
  color:#fff;
  text-decoration: none;
  padding: 10px;
  border-radius: 30px;
  color: var(--bluetext);
  margin-right: -100px;
  font-family:var(--dinregular);
  font-size: 16px;
  letter-spacing: 1px;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.text-btn:hover {
  color: #fff;
  background-color: var(--bluetext);
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.text-btn:active {
  opacity: .8;
}

/* Footer */

.footer {
  height: 40px;
  width: calc(100% - 150px);
  display: none;
}

/* Sign in page */

.sign-in-div {
  width: 80%;
  max-width: 350px;
  margin: 0px auto 80px auto;
  padding: 100px;
}

.sign-in-div img {
  margin: 60px auto;
  display: block;
}

.sign-in-div label {
  margin-bottom: 5px;
  display: block;
}

.sign-in-div .error-message {
  margin-bottom: 20px;
  position: absolute;
  top: 225px;
  left: auto;
  right: auto;
}

.sign-in-div input[type="text"], .sign-in-div input[type="password"], .sign-in-div input[type="email"]{
  width: 100%;
  /*background-color: rgba(255, 255, 255, 0.1);*/
  border: 1px solid #000;
  border-radius: 4px;
  padding: 10px;
  color: #fff !important;
  margin-bottom: 20px;
  font-family:var(--dinmedium);
  font-size: 16px;
}

.sign-in-div input:-webkit-autofill, .sign-in-div input:-webkit-autofill:hover, .sign-in-div input:-webkit-autofill:focus, .sign-in-div input:-webkit-autofill {
  -webkit-text-fill-color: #fff;
}

input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus input:-webkit-autofill, textarea:-webkit-autofill, textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus, select:-webkit-autofill, select:-webkit-autofill:hover, select:-webkit-autofill:focus {
  background-color: rgba(255, 255, 255, 0.1);
  background-image: none;
  color: #fff;
  transition: background-color 5000s ease-in-out 0s;
}

input:invalid {
  border: 1px solid #B62626 !important;
  box-shadow: 0px 0px 0px !important;
}


.sign-in-div .ki-btn2, .notfound-wrap .ki-btn2 {
  margin-top: 10px;
  text-decoration: none !important;
  height: auto !important;
  padding: 8px 14px;
}

.sign-in-field input[type="radio"] {
  margin-left: 5px !important;
  display: inline;
  width: 25px;
  position: relative;
  float: right;
  margin-top: 1px;
}

.sign-in-field .radio-inline {
  width:64px !important;
  right: 0px;
  padding-left: 0px;
}

.sign-in-field textarea {
  height: auto !important;
}

.caption-text {
  color: #6F7F8F;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-style: normal;
}

.label-fix label {
  margin-bottom: 5px;
  font-family: "Roboto",sans-serif;
  font-size: 14px;
  color: #8A95A1;
  font-weight: 400;
}



.login-trouble {
  margin-top: 4px;
}

.login-trouble a, .login-trouble a:active,.login-trouble a:visited {
  color: #fff;
}

.notfound-wrap {
  width:100%;
  max-width:460px;
  margin:0 auto;
  margin-top: 150px;
}

/* table */

table {
  width: 100%;
}

form[name=analysis] table {
  margin-left: -35px;
}

.table-arrows {
  display: inline-block;
  background-image: url(/images/sort-arrow-up-down.svg);
  height: 12px;
  background-size: 9px;
  background-repeat: no-repeat;
  background-position: 50% 100%;
  width: 10px;
  margin-left: 7px;
}

.sorttable_sorted .table-arrows {
  background-image: url(/images/sort-arrow-up-down-down.svg);
}

.sorttable_sorted_reverse .table-arrows {
  background-image: url(/images/sort-arrow-up-down-up.svg);
}

#sorttable_sortfwdind {
  display: none !important;
}

#sorttable_sortrevind {
  display: none !important;
}

.sortable {
  position: relative;
}

.sortable .ui-selectmenu-button.ui-button {
  margin-bottom: 0px;
}

table.sortable tr th{
  min-width: 115px;
  padding: 0px 20px 0px 5px;
}

th.heading-3 {
  font-family: var(--dinmedium);
  font-size: 12px;
  text-transform: uppercase;
}

table .heading-3 {
  max-width: 160px;
}

.sortable td .small-text {
  height: 100%;
  vertical-align: middle;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center;
}


tr.study td:first-child, tr.people td:first-child{
  padding-left: 15px;
}

tr.rally td:nth-child(2) {
  padding-left: 15px;
}

th.study.heading-3:nth-child(4), th.study.heading-3:nth-child(5) {
  width: 200px;
}

th.rally.heading-3:first-child, th.rally.heading-3:nth-child(2){
  text-align: center;
  min-width: 30px;
}

.table-text-overflow {
  white-space: nowrap;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}

tr.rally td:nth-child(3) .table-text-overflow {
  max-width: 400px;
}



#sorttable_sortfwdind, #sorttable_sortrevind{
  font-size: 16px;
  position: absolute;
  top: 0px;
  left: auto;
}

#page-header {
  background: white;
  box-shadow: 0px 3px 5px #0000000D;
  position: fixed;
  z-index: 2;
  width: 100vw;
  left: 0px;
  top: 0px;
  padding: 5px 0px 20px;
}

#page-header .container {
  width: 100%;
  max-width: 900px;
  margin: 0px auto;
  padding: 0px;
}

#page-header .row {
  max-width: 900px;
  margin-left: -20px;
}

/*#planning {*/
/*margin-top: 20px;*/
/*}*/

#rally-planning {
  display: block;
  position: relative;
  top: -280px;
  visibility: hidden;
}

#rally-analysis, #rally-documentation, #rally-complete {
  display: block;
  position: relative;
  top: -180px;
  visibility: hidden;
}


.rally_submit.container {
  width: 100%;
  margin-right: auto;
  display: none;
}

.rally_submit .row {
  width: 100%;
}

/* study */
.form-elem ul.multiline_element li text{
                                              color: var(--darktext);
                                            }

/* dashboard */

.dashboard {
  border-spacing: 0px;
  box-shadow: 1px 0px 4px 3px #f4f3f1;
  width: 700px;
  margin: 20px auto;
}

.dashboard th {
  font-family:var(--dinmedium);
  font-size: 12px;
  text-transform: uppercase;
  color: var(--darktext);
  letter-spacing: 1.1px;
  font-weight: normal;
  text-align: left;
  padding-bottom: 10px;
  background-color: #fdfcfa;
  box-shadow: 1px 0px 0px 10px #fdfcfa;
  padding: 10px;
}

.dashboard tr td {
  padding:17px 10px;
  border-bottom: 2px solid #f4f3f1;
  font-family:var(--dinregular);
  font-size: 13px;
  color: var(--darktext);
}

.dashboard tr {
  background-color: white;
}



td a.ellipsis{
  display: inline-block;
  height: 20px;
  width: 20px;
  background-repeat: no-repeat;
  background-position: 50%;
  /*background-image: url(/images/green_down_arrow.svg);*/
}

/* td a.ellipsis:hover{
    background-image: url(/images/dashboard-ellipse-hover.svg);
}

td a.ellipsis.active {
    background-image: url(/images/dashboard-ellipse-active.svg);
} */

.participants ul {
  margin: 0px 0px 0px 0px;
}

.participants ul li {
  margin: 0px 10px 10px 0px;
}

.participants ul li div:first-child {
  margin: 0px 5px 0px 0px;
}

.participants .ki-btn .remove-margin{
  margin: 0px 10px 0px 0px;
}

.participants-box ul {
  padding: 0px;
  display: inline;
}

.participants-box ul .remove-margin.close-icon {
  display: inline-block;
  -ms-transform: translate(0px,11px); /* IE 9 */
  -webkit-transform: translate(0px,11px); /* Safari */
  transform: translate(0px,11px); /* Standard syntax */
}


.close-icon {
  width: 34px;
  height: 34px;
  cursor: pointer;
}

.close-icon-adjust {
  right:-50px !important;
}

.close-icon svg {
  height: 100%;
  width: 100%;
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.close-icon svg #Layer_2 {
  fill:#fff;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.close-icon svg #Layer_1 {
  fill:#70b6bc;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}



.close-icon:hover svg #Layer_2 {
  fill:#70b6bc;
}

.close-icon:hover svg #Layer_1 {
  fill:#fff;
}


.form-question {
  outline: none !important;
}

.form-question .container {
  display: flex;
  background: unset;
  margin: 0px;
  padding: 0px;
}

.form-question tags-input .tags {
  border: unset;
  background-color: #fdfcfa;
  box-shadow: unset;
  outline: none;
}

body.modal-open {
  overflow: hidden;
}
/* dropdown menu */


.ui-selectmenu-menu.ui-front.ui-selectmenu-open ul {
  max-height: 300px;
  overflow: scroll;
  box-shadow: 4px 4px 10px #0000001f;
}

.ui-selectmenu-button, .ui-button.ui-state-disabled:hover,
.ui-button.ui-state-disabled:active {
  background-color: #fff;
  border: none;
}


.ui-selectmenu-button.ui-button {
  width:100%;
  max-width: 130px;
  outline: 0px;
  margin-bottom: 30px;
}

.multiselect .ui-selectmenu-button.ui-button:focus{
  outline: 0px solid #3D5052;
}

.ui-selectmenu-text {
  font-family: var(--dinregular);
  margin: 16px 0px;
  text-transform: capitalize;
  color: var(--darktext);
}

.ui-selectmenu-text:hover {
  color: var(--bluetext);
}

.ui-widget {
  font-size: 13px;
}

.ui-widget:hover{
  /*color: var(--bluetext);*/
}

.ui-menu .ui-menu-item{
  margin: 18px 0px;
}

.ui-widget.ui-widget-content{
  padding: 20px;
  border: 1px solid white;
  box-shadow: 4px 4px 10px #0000001f;
}

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active,
a.ui-button:active,
.ui-button:active,
.ui-button.ui-state-active:hover {
  /*border: 1px solid #003eff;*/
  /*background: #007fff;*/
  font-weight: normal;
  color: var(--bluetext);
}

.ui-state-active a,
.ui-state-active a:link,
.ui-state-active a:visited {
  font-weight: normal;
  color: var(--bluetext);
}



/* form dropdown  */

select.participants, select.roles, .form-element .ui-selectmenu-button, .form-question .ui-selectmenu-button {
  width: unset;
  max-width: 200px;
  border: 1px solid #70b6bc;
  border-radius: 30px;
}

select.participants, select.roles, .form-element .ui-selectmenu-button.ui-button, .form-question .ui-selectmenu-button.ui-button {
  min-width: 200px;
  width: unset;
  height: 34px;
  padding: 0px 16px;
  outline: 0px solid #70b6bc;
  padding-left: 23px;
  color: var(--darktext);
  margin-right: 5px;
  margin-bottom: -2px;
}

.participant_role select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.form-element .ui-selectmenu-text, .form-question .ui-selectmenu-text {
  margin: 0px 0px;
}

.form-question .ui-selectmenu-icon.ui-icon.ui-icon-triangle-1-s, .form-element .ui-selectmenu-icon, .form-question .ui-selectmenu-icon
.form-element .ui-selectmenu-icon button:active, .form-question .ui-selectmenu-icon button:active {
  background-image: url(/images/sort-arrow-down.svg);
}

.form-element .ui-selectmenu-button.ui-button:hover, .form-question .ui-selectmenu-button.ui-button:hover {

}

.form-element .ui-selectmenu-icon.ui-icon, .form-question .ui-selectmenu-icon.ui-icon {
  margin: 12px 0px 0px 10px;
}

.form-element .ui-selectmenu-text, .form-question .ui-selectmenu-text {
  line-height: 30px;
  font-size: 16px;
  font-family: var(--dinregular);
}

.form-question .multiselect, .form-element .multiselect {
  margin-bottom: 30px;
}

.form-question .ki-btn {
  /* margin-top: 40px; */
}


.form-question li .close-icon {
  float: right; margin-right: 20px;margin-top: 3px;
}

.heading-3-nav .ki-btn{
  color:var(--bluetext) !important;
}

.heading-3-nav .ki-btn:hover{
  color:#fff !important;
}

.form-question ul {
  padding-left: 0px;
}

.dropdown.participants, .dropdown.roles {
  padding: 0px;
}

/* rally form */

.form-question h1.heading-1{
  margin-top: 15px;
  margin-bottom: 50px;
}

.form-wrap {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
}

.form-wrap {
  margin: 0px 248px 0px 0px;
}

.form-wrap .form-element {
  position: relative;
  max-width: 620px;
}

.form-question {
  max-width: 620px;
}

.revisions {
  max-width: 620px;
}

.revisions:before, .revisions:after {
  padding: inherit;
  letter-spacing: 4px;
  color: #D3D3D3;
  display: block;
  content: "\B7\B7\B7\B7\B7\B7\B7\B7\B7\B7\B7\B7\B7\B7\B7\B7\B7\B7\B7\B7\B7\B7\B7\B7\B7\B7\B7\B7";
}

.form-wrap label {
  font-family:var(--dinmedium);
  font-size: 12px;
  /* text-transform: uppercase; */
  color: var(--darktext);
  letter-spacing: .5px;
  font-weight: normal;
}

.form-wrap input {
  display: block;
  width: 100%;
  /* margin-bottom: 50px; */
  width: 100%;
  background-color: white;
  border: 1px solid #cbd0d1;
  border-radius: 4px;
  padding: 10px;
  color: var(--darktext);
  font-family:var(--dinregular);
  font-size: 16px;
}

.form-wrap .caption-text {
  margin-bottom: 20px;
  display: block;
}

.form-question .caption-text {
  margin-bottom: 5px;
  margin-top: 10px;
}

.form-wrap form table.sortable {
  border-spacing: 0px 5px;
  border-collapse: separate;
}

.form-wrap form table.sortable tr {
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.form-wrap form table.sortable tr:hover {
  transform: translateX(0px) translateY(-2px);
  -webkit-box-shadow: 0px 4px 9px -1px #6F7D7D;
  box-shadow: 0px 4px 9px -1px #6F7D7D;
}

.form-wrap form table.sortable tr:hover td {

}


.form-wrap form table.sortable tbody tr td {
  height: 55px;
  background-color: #fff;
  margin: 5px;
  box-shadow: 2px 1px 3px #0000001f;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.form-wrap form table.sortable tbody tr td.lock {
  background-color: unset;
  box-shadow: unset;
}

.lock a img {
  width: auto !important;
}

.lock {
  box-shadow: -13px 4px 6px 24px #fdfcfa !important;
}

.form-wrap form table.sortable thead tr {
  transform: translateX(0px) translateY(0px) !important;
  -webkit-box-shadow: 0px 0px 0px 0px #6F7D7D !important;
  box-shadow: 0px 0px 0px 0px #6F7D7D !important;
}


.form-wrap form tbody tr td:nth-child(2), .form-wrap form thead tr td:nth-child(2){
  /*text-align: center;*/
  /*padding-left: 20px;*/
  /*padding-right: 25px;*/
}


.form-wrap form tbody tr td:last-child {
  padding-top: 10px;
}

.form-wrap form tbody td {
  vertical-align: middle;
}

td.heading-3 select {
  margin-bottom: 0px;
}


.required:before {
  content: "";
  background-color: var(--bluetext);
  height: 100%;
  width: 5px;
  position: absolute;
  display: block;
  margin-left: -15px;
}

.form-notification {
  font-family:var(--dinregular);
  font-size: 13px;
  color: var(--bluetext);
  letter-spacing: 1.1px;
  font-weight: normal;
  font-style: italic;
  visibility: hidden;
}

.required .form-notification {
  visibility: visible;
}

.multiselect-native-select {
  display: block;
}

.btn-group button {
  text-align: left;
}

.btn-group {
  position: relative !important;
  width: 100% !important;
}

.multiselect-container {
  width: 100% !important;
}

.multiselect-search {
  margin-top: 0px !important;
}

.dropdown-menu {
  z-index: 2 !important;
}

.multiselect-container input[type=checkbox] {
  visibility: hidden !important;
}

.dropdown-menu > li > a {
  white-space: normal !important;
}

.multiselect-container > li > a > label {
  margin-bottom: 15px !important;
  text-transform: none !important;
  font-size:13px !important;
}

.multiselect-container > li > a.multiselect-all label {
  font-weight: normal !important;
}

.multiselect-search {
  padding: 0px 5px !important;
}

.caret {
  position: absolute;
  right: 10px;
  top: 15px;
}

.dropdown-menu > .active > a, .dropdown-menu > .active > a:focus, .dropdown-menu > .active > a:hover {
  background-color: var(--bluetext) !important;
}

.multiselect-native-select {
  font-family:var(--dinregular) !important;
}

input[type=text], select, textarea, input[type=date], input[type=password], input[type=email] {
  font-family:var(--dinregular) !important;
  font-size: 16px !important;
  color: #363636;
}

.email.input[type=text], select, textarea, input[type=password], input[type=email] {
  color: var(--darktext);
  margin-top: 0px;
}

/* form */
input[type=checkbox] {
  width: 30px;
}

.form-question {
  margin-bottom: 40px;
  position: relative;
}

.form-section-heading {
  border-bottom: 2px dotted #202A2B;
  margin: 20px 0px 50px;
  max-width: 700px;
}

.form-section-heading .heading-2 {
  margin-bottom: 2px;
}

.text-block {
  display: block;
  margin: 15px 0px 15px 0px;
}

.form-question .text-block {
  max-width: 620px;
}

.form-question.agreement h3 {
  display: block;
  margin: 30px 0px 30px 0px;
}

.required > .heading-3-2:before {
  display: block;
  width: 100%;
  font-family:var(--dinregular);
  font-size: 13px;
  color: var(--bluetext);
  letter-spacing: 1.1px;
  font-weight: normal;
  font-style: italic;
  text-transform: none;
}

.required > .heading-3:before {
  content: "Needs your contribution";
  display: block;
  width: 100%;
  font-family:var(--dinregular);
  font-size: 13px;
  color: var(--bluetext);
  letter-spacing: 1.1px;
  font-weight: normal;
  font-style: italic;
  text-transform: none;
}

.hidden{
  display: none;
}

.animate-if.ng-enter, .animate-if.ng-leave {
  transition:all cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.5s;
}

.animate-if.ng-enter,
.animate-if.ng-leave.ng-leave-active {
  opacity:0;
}

.animate-if.ng-leave,
.animate-if.ng-enter.ng-enter-active {
  opacity:1;
}

.profile_pic {
  height: 100px;
}

th {
  padding: 5px;
  vertical-align: middle;
}

td {
  padding: 5px;
  vertical-align: top;
}


.set_status_contain {
  position: absolute;
  right: 0;
  bottom: -24px;
}

.set_status_contain:after {
  content: "";
  height: 15px;
  width: 15px;
  display: block;
  position: absolute;
  right: -2px;
  top: 11px;
  background-image: url(/images/green_down_arrow.svg);
  background-position: 50%;
  background-size: 10px;
  background-repeat: no-repeat;
  z-index: 0;
}

.set-status {
  display: inline-block;
  vertical-align: sub;
  height: 34px;
}

.status_select {
  background-color: #fdfcfa;
  font-size: 14px !important;
  padding: 2px;
  width: inherit;
  margin-top: 0px;
  border: 0px;
  padding-right: 15px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-transform: capitalize;
  display: inline-block;
  vertical-align: top;
  height: 34px;
  z-index: 1;
  position: relative;
  background-color: transparent;
}



td select.status_select {
  max-width: 100px;
}

.status_select .optgroup {
  font-size: x-small;
}

.permission_select {
  background-color: #fdfcfa;
  font-size: 12px !important;
  padding: 2px;
  width: inherit;
  margin-top: 0px;
}

.permission_select .optgroup {
  font-size: x-small;
}

#dialog {
  text-align: center;
  padding: 10px;
  border: 0px;
  color: var(--darktext);
  /* background-color: #4fb9c2; */
  /* color: white; */
  display: none;
}

.ui-button-icon-only {
  text-indent: 0px;
  width: auto !important;
}

.ui-button-icon-only .ui-icon {
  /* position: relative; */
}

.ui-widget-header {
  border: 0px;
  background-color: transparent;
  position: absolute;
  bottom: 10px;
  width: 100%;
  text-align: center;
  margin-left: -20px;
}

.ui-dialog-titlebar-close {
  display: inline-block;
  text-transform: uppercase;
  color: #fff;
  text-decoration: none !important;
  padding: 1px 17px;
  border-radius: 30px;
  border: 1px solid var(--bluetext);
  color: var(--bluetext);
  font-family: var(--dinregular);
  font-size: 14px;
  letter-spacing: 1px;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  background-color: transparent;
  height: 34px;
  line-height: 32px;
  outline: none;
  font-size: 14px;
  font-weight: 400;
}

.ui-dialog-titlebar-close:hover {
  color: #fff;
  background-color: var(--bluetext);
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-ou;
}

.upload-modal {
  margin-left: auto;
  margin-right: auto;
  margin-top: 10%;
}

.upload-modal .ui-dialog-content {
  border: 0px !important;
  height: auto !important;
}

.upload-modal .ui-widget-header {
  border: 0px;
  background-color: transparent;
  position: absolute;
  bottom: 20px;
  text-align: center;
  margin-left: -20px;
  width: 100px;
  right: 20px;
  z-index: 2;
}

.upload-modal .ki-btn2:hover {
  border: 1px solid var(--bluetext);
}

.green {
  color: #008000;
  font-style: italic;
}

.red {
  color: red;
  font-style: italic;
}

div .participants{
  display:inline-block;
  overflow:hidden;
}
.participants{
  padding: 5px;
  display: inline-block;
  vertical-align: top;
  background: white;
}

.roles{
  display: inline-block;
  vertical-align: top;
}

div .roles select{
  border-radius:36px;
  display:inline-block;
  overflow:hidden;
}

.roles select{
  width:140px;
  height:40px;
  padding: 5px;
  background: white;
}

.rally_submit {
  color: #4fb9c2;
  float: right;
  font-family: var(--dinmedium);
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 1.1px;
  font-weight: normal;
}

.rally_submit-2 {
  color: #4fb9c2;
  float: left;
  font-family: var(--dinmedium);
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 1.1px;
  font-weight: normal;
}

.sprint_letter{
  width: 100px;
  background-color: white;
  padding-left: 0px;
}

.form-question .sprint_letter {
  text-align: center;
  max-width: 85px;
  color: #202A2B;
  padding: 0px 10px;
}


.top_link{
  color: #4fb9c2;
  font-family: var(--dinmedium);
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 1.1px;
  font-weight: normal;
}

.top_link:hover{
  color: #4fb9c2;
  text-decoration: none;
}

.download-link{
  color: #4fb9c2;
  font-family: var(--dinmedium);
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 1.1px;
  font-weight: normal;
  text-decoration: none;
}

.download-link:hover{
  color: #4fb9c2;
  text-decoration: none;
}

.crumb-font {
  font-family: $roboto;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1.1px;
}

.top_link.crumb-font {
  text-decoration: none;
}

.rally_title .heading-1 {
  margin-bottom: 10px;
}


li.tag-item.ng-scope{
linear-gradient:(to bottom,#008080 0,#008080 47%,#4fb9c2 100%);
  background: -moz-linear-gradient(90deg, #4fb9c2 0%, #008080 100%); /* ff3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #008080), color-stop(100%, #4fb9c2)); /* safari4+,chrome */
  background: -webkit-linear-gradient(90deg, #4fb9c2 0%, #008080 100%); /* safari5.1+,chrome10+ */
  background: -o-linear-gradient(90deg, #4fb9c2 0%, #008080 100%); /* opera 11.10+ */
  background: -ms-linear-gradient(90deg, #4fb9c2 0%, #008080 100%); /* ie10+ */
  background: linear-gradient(0deg, #4fb9c2 0%, #008080 100%); /* w3c */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#008080', endColorstr='#4fb9c2',GradientType=0 ); /* ie6-9 */
  color: white;
}

.tag-item {
linear-gradient:(to bottom,#008080 0,#008080 47%,#4fb9c2 100%);
  background: -moz-linear-gradient(90deg, #4fb9c2 0%, #008080 100%); /* ff3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #008080), color-stop(100%, #4fb9c2)); /* safari4+,chrome */
  background: -webkit-linear-gradient(90deg, #4fb9c2 0%, #008080 100%); /* safari5.1+,chrome10+ */
  background: -o-linear-gradient(90deg, #4fb9c2 0%, #008080 100%); /* opera 11.10+ */
  background: -ms-linear-gradient(90deg, #4fb9c2 0%, #008080 100%); /* ie10+ */
  background: linear-gradient(0deg, #4fb9c2 0%, #008080 100%); /* w3c */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#008080', endColorstr='#4fb9c2',GradientType=0 ); /* ie6-9 */
  color: white;
}

.multiselect{
  border-radius:36px;
  height: 34px;
  display:inline-block;
  overflow:hidden;
}

.arms ol{
  list-style-type: none;
}

.form-repeat{
  margin-top: 20px;
  background-color: whitesmoke;
  padding: 50px;
  border-radius:15px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  list-style-type: none;
  border: 1px solid #d8d8d8;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
}

.form-repeat ul{
  list-style-type: none;
}
.form-repeat ul li{
  list-style-type: none;
  margin-bottom: 0px;
  border-radius: 15px;
}

.rally-form-repeat{
  margin-top: 20px;
  background-color: white;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 15%;
  padding-bottom: 0px;
  margin-bottom: 20px;
  list-style-type: none;
}



input[type=text].enroll_age {
  background-color: white;
  width: 50px;
  margin-right: 10px;
  float: left;
  border-radius: 30px;
  height: 34px;
  margin-top: 0px;
}

.multiselect-container .radio input[type="radio"]{
  width: 13px;
}

.enroll_age_select{
  background-color: white;
  width: auto;
  margin-right: 10px;
}

ul.phase{
  margin-left: -38px;
  margin-top: 30px;
  width: 80%;
  margin-bottom: 10px;
  list-style-type: disc;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;
  z-index: 2;
}

.rally ul.phase {
  max-width: 650px;
}

ul.phase:after {
  display: block;
  content: "";
  position: absolute;
  width: calc(100% - 40px);
  z-index: 1;
  background-image: url(/images/dot.svg);
  background-repeat: repeat-x;
  bottom: 9px;
  background-size: 5px;
  height: 6px;
  opacity: .15;
}


ul.phase > li {
  color: #3d5052;
  position: relative;
}

ul.phase li{
  display: inline;
  font-size: 14px;
  font-family: var(--dinregular);
  position: relative;
  z-index: 3;
  background-color: #fff;
  padding: 5px;
  text-align: center;
}

ul.phase li:first-child {
  font-style: italic;
  margin-right: 10px;
}

ul.phase li+li+li:before {
  /* padding: inherit;
  letter-spacing: 2px;
  color: #D3D3D3;
  content: "\B7\B7\B7\B7"; */
}

ul.phase li a {
  color: #D8D8D8;
  text-decoration: none;
  position: relative;

}

ul.phase li a:hover {
  color: #4fb9c2;
}

ul.phase li a.active {
  color: #4fb9c2;
}

.page-header .header-buttons {
  min-width: 320px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.page-header .heading-1 {
  margin-bottom: 0px;
}

.header-buttons .ki-btn {
  margin-left: 20px;
}

.content {
  padding: 16px;
}

.image-upload > input {
  visibility:hidden;
  width:0;
  height:0
}

.status_select {
  /*display: inline !important;*/
}


.master-submit{
  position: absolute;
  right: 0;
  bottom: 0;
  /*display: none;*/
}


.master-submit:disabled {
  opacity: .2;
  cursor: default;
  border: 1px solid #333 !important;
  color: #333 !important;
  background-color: transparent !important;
}

.align-self-center.col-3{
  position: relative;
}

.sprint_letter select{
  width:140px;
  height:40px;
  border:0px;
  outline:none;
}

.overlay {
  /*position: fixed;*/
  width: 100%;
  height: 100%;
  background: black url(/images/uploading.gif) center center no-repeat;
  opacity: .5;
}

#matrix {
  max-width: 900px;
  margin-bottom: 30px;
}

.form-wrap form #matrix tbody tr td:last-child {
  padding-top: 0px;
}

/* #matrix tbody tr td {

} */

.epoch_arms {
  margin-bottom: 20px;
  padding: 5px;
  width: 40%;
  background-color: whitesmoke;
}

.body-text {
  margin-bottom: 10px;
}

.content-block-images{
  border-radius: 100%;
  background-color: #fff;
  border: 1px solid #4fb9c2;
  padding: 5px;
  height: 40px;
  width: 40px;
}

.content-text-block {
  color: #4fb9c2;
  font-family: var(--dinregular);
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 1.1px;
  font-weight: inherit;
}

.content-text-block.heading-4 {
  font-size: 12px;
  color:#202A2B;
  font-family: var(--dinmedium);
  margin-top: 30px;
}


.epoch-card {
  border-radius: 0px;
  padding: 0px;
  background-color: transparent;
}

.epoch-card-title {
  background-color: #F7F7F7;
  padding:20px;
  color: #3D5052;
  border-radius: 15px 15px 0px 0px;
}

.epoch-full {
  width: 100%;
}

.epoch-half {
  width: 45%;
}

.epoch-card-content {
  padding: 20px;
  background-color: #FFFFFF;
  border-radius: 0px 0px 15px 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap:nowrap;
  flex-wrap:nowrap;
}

.arm-card .epoch-card-content {
  -ms-flex-wrap:wrap;
  flex-wrap:wrap;
}

.add-content-icons {
  border-radius: 100%;
  display: inline-block;
  /* background-color: #fff; */
  border: 1px solid #4fb9c2;
  padding: 5px;
  height: 34px;
  width: 34px;
  padding-top: 2px;
  text-align: center;
  vertical-align: top;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  position: relative;
  cursor: pointer;
}

.add-content-icons svg {
  margin-top: 7px !important;
}

.add-content-icons:hover {
  background-color: #4fb9c2;
}

.add-content-icons:hover svg path, .add-content-icons:hover svg, .add-content-icons:hover circle {
  fill: #fff;
  stroke: #fff;
}

.add-content-icons.image-textarea svg {
  margin-top: 7px;
}

.ki-btn .ki-btn-upload {
  margin-top: 10px;
}

.rally-u-list {
  background-color: white;
  border: 1px solid #F7F7F7;
  border-radius: 10px;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  padding-left: 0px;
}

.rally-u-list:hover {
  -webkit-box-shadow: 0px 3px 8px 0px rgba(87,87,81,0.32);
  -moz-box-shadow: 0px 3px 8px 0px rgba(87,87,81,0.32);
  box-shadow: 0px 3px 8px 0px rgba(87,87,81,0.32);
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  border-radius: 5px;
  transform: translateX(0px) translateY(-3px);
}

.rally-u-list .rally-form-repeat {
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.rally-u-list:hover .rally-form-repeat {
  /* margin-top:18px; */
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}



.rally-card-title {
  position: absolute;
  left: 20px;
  top: 18px;
  color: #000;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.rally-card-title {
  font-family: var(--dinmedium);
  font-size: 12px;
}


.rally-u-list:hover .rally-card-title {
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  /* top:9px; */
}

.rally-delete {
  background-color: whitesmoke;
  border-radius: 4px 4px 0px 0px;
  padding-right: 20px;
  text-align: right;
  position: relative;
}

.rally-delete a{
  background-color: whitesmoke;
  padding-bottom: 5px;
  margin-bottom: 9px;
  width: 29px;
  /* height: 34px; */
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  border-radius: 50px;
  margin-top: 8px !important;
  font-size: 20px;
  text-align: center;
  padding: 0px;
  padding-top: 9px;
  font-family: sans-serif;
  font-weight: 100;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.rally-delete a:hover {
  width:102px;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  padding-left: 12px;
}

.delete-trigger {
  /* width: 40px;
  opacity: .5;
  position: absolute;
  right: 18px;
  top: 10px;
  height: 36px; */
}

.rally-delete .ki-btn {
  text-align: center;
  /* padding-top: 1px;
  padding-bottom: 7px;
  padding-right: 0px;
  padding-left: 2px; */
  overflow: hidden;
  padding: 1.76px 0px 5px;
  padding:0px 9px 4px !important;
}



.rally-delete .ki-btn svg {
  margin-right: -15px;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  margin-bottom: -1px;
}

.rally-delete .ki-btn:hover svg {
  margin-right: 0px;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.rally-delete .ki-btn:hover svg g {
  stroke:#fff;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.rally-delete .ki-btn span {
  opacity: 0;
  width: 0px;
  margin-right: 7px;
  padding-left: 3px;
  display: inline-block;
  margin-bottom: -10px;
  margin-top: 0px;
  font-size: 12px;
  text-transform: none;
  text-align: left;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out
}


.rally-delete .ki-btn:hover span {
  opacity: 1;
  width: 60px;
  -webkit-transition: all 302ms ease-in-out;
  -moz-transition: all 302ms ease-in-out;
  -ms-transition: all 302ms ease-in-out;
  -o-transition: all 302ms ease-in-out;
  transition: all 302ms ease-in-out
}


.rally-form-repeat input, .rally-form-repeat textarea, .rally-form-repeat .ki-btn {
  margin: 10px 0px 30px;
}

.rally-form-repeat .ki-btn {
  margin: 10px 0px 30px !important;
  padding: 6px 17px 6px 12px;
}

.rally-u-list:hover .rally-delete .ki-btn {
  /* margin-top:3px !important; */
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}


.rally-delete .ki-btn:hover svg g {
  stroke:#fff;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.rally-delete .ki-btn span {
  opacity: 0;
  width: 0px;
  margin-right: 11px;
  padding-left: 3px;
  display: inline-block;
  margin-bottom: -10px;
  margin-top: 0px;
  font-size: 12px;
  text-align: left;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  margin-top: 6.5px;
}


.rally-delete .ki-btn:hover span {
  opacity: 1;
  width: 60px;
  -webkit-transition: all 302ms ease-in-out;
  -moz-transition: all 302ms ease-in-out;
  -ms-transition: all 302ms ease-in-out;
  -o-transition: all 302ms ease-in-out;
  transition: all 302ms ease-in-out;
  padding-left: 5px;
}


.rally-form-repeat input, .rally-form-repeat textarea, .rally-form-repeat .ki-btn {
  margin: 10px 0px 30px;
}

.rally-form-repeat .ki-btn {
  margin: 10px 0px 30px !important;
  padding: 6px 17px 6px 12px;
}

.rally-u-list:hover .rally-delete .ki-btn {
  /* margin-top:3px !important; */
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.background_image {
  outline: none;
  background: unset;
  background-color: #3D50521A !important;
  display: inline-block;
  text-transform: uppercase;
  color: #fff;
  text-decoration: none !important;
  padding: 0px 17px;
  border-radius: 30px;
  border: none;
  color: var(--darktext);
  font-family: var(--dinregular);
  font-size: 14px;
  letter-spacing: 1px;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  height: 34px;
  line-height: 32px;
  outline: none;
}

.data_description_image {
  outline: none;
  background: unset;
  background-color: #3D50521A !important;
  display: inline-block;
  text-transform: uppercase;
  color: #fff;
  text-decoration: none !important;
  padding: 0px 17px;
  border-radius: 30px;
  border: none;
  color: var(--darktext);
  font-family: var(--dinregular);
  font-size: 14px;
  letter-spacing: 1px;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  height: 34px;
  line-height: 32px;
  outline: none;
}

.methods_detail_image {
  outline: none;
  background: unset;
  background-color: #3D50521A !important;
  display: inline-block;
  text-transform: uppercase;
  color: #fff;
  text-decoration: none !important;
  padding: 0px 17px;
  border-radius: 30px;
  border: none;
  color: var(--darktext);
  font-family: var(--dinregular);
  font-size: 14px;
  letter-spacing: 1px;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  height: 34px;
  line-height: 32px;
  outline: none;
}

.results_detail_image {
  outline: none;
  background: unset;
  background-color: #3D50521A !important;
  display: inline-block;
  text-transform: uppercase;
  color: #fff;
  text-decoration: none !important;
  padding: 0px 17px;
  border-radius: 30px;
  border: none;
  color: var(--darktext);
  font-family: var(--dinregular);
  font-size: 14px;
  letter-spacing: 1px;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  height: 34px;
  line-height: 32px;
  outline: none;
}

.close_image {
  margin: 0 0 4px 4px;
  padding: 3px 0px 4px 1px;
  border: none;
  cursor: pointer;
  vertical-align: middle;
  font-weight: bold;
  font-family: var(--dinregular);
  color: #fff;
  background: #3D50524d;
  border-radius: 10px;
  display: inline-block;
  text-transform: uppercase;
  height: 18px;
  width: 18px;
  text-align: center;
  line-height: 12px;
  font: 700 12px Arial,sans-serif;
}

input[type="file"] {
  color: #000000;
}

#pre-planning {
  margin-top: 230px;
}

.ui-tooltip {
  position: absolute;
  display: inline-block;
  background-color: #4fb9c2 !important;
  color: white;
  text-align: center;
  border:0px !important;
  outline: none !important;
  padding: 4px 12px !important;
  margin: 0px !important;
  border-radius: 4px;
  font-size: 13px;
  font-family: var(--dinregular);
  margin-top: -12px !important;
}




.tooltip-inner {
  text-align: center;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  margin-bottom: 6px;
  background-color: #505050;
  font-size: 14px;
  padding: 0px;
  height: 0px;
  margin: 0px;
}




.ui-tooltip-content::after, .ui-tooltip-content::before {
  bottom: 100%;
  /* left: 15%; */
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}



.ui-tooltip-content::before {
  border-color: rgba(194, 225, 245, 0);
  border-bottom-color: #4fb9c2;
  border-width: 8px;
  margin-left: -2px;

}

.ui-widget-overlay {
  background: #3D5052;
  opacity: .5;
}

.ui-widget.ui-widget-content {
  padding: 20px;
  border: 1px solid white;
  box-shadow: 4px 4px 10px #0000001f;
}

.ui-widget.ui-widget-content {
  /* border: 1px solid #c5c5c5; */
}
.ui-widget {
  font-size: 13px;
}
.ui-corner-all, .ui-corner-bottom, .ui-corner-right, .ui-corner-br {
  /* border-bottom-right-radius: 3px; */
}
.ui-corner-all, .ui-corner-bottom, .ui-corner-left, .ui-corner-bl {
  /* border-bottom-left-radius: 3px; */
}
.ui-corner-all, .ui-corner-top, .ui-corner-right, .ui-corner-tr {
  /* border-top-right-radius: 3px; */
}
.ui-corner-all, .ui-corner-top, .ui-corner-left, .ui-corner-tl {
  /*border-top-left-radius: 3px;*/
}
.ui-widget-content {
  border: 1px solid #dddddd;
  background: #ffffff;
  color: #333333;
}
.ui-widget {
  /* font-family: Arial,Helvetica,sans-serif; */
  /* font-size: 1em; */
}
.ui-front {
  z-index: 100;
}

.ui-tooltip {
  color: white;

}

.add-new-user {
  color: #70B6BC;
  font-family: var(--dinregular);
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1.1px;
  line-height: 14px;
  width: 350px;
  text-align: left;
  text-transform: uppercase;
  text-decoration: none;
}

.ui-dialog {
  border-radius: 8px;
}

.ui-dialog .heading-5 {
  font-family: var(--dinbold);
  text-transform: none;
  font-size: 28px;
  letter-spacing: normal;
}

.ui-dialog .heading-5-underline {
  border-bottom: 2px dotted #202A2B;
}

.ui-dialog-titlebar-close {
  visibility: hidden;
}

.heading-5-underline {
  padding: inherit;
  letter-spacing: 4px;
  color: #808080;
  display: block;
  margin-bottom: 40px;
  border-bottom: 3px dotted #D8D8D8;
}

.heading-5 {
  color: #202A2B;
  font-family: var(--dinregular);
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 1.6px;
  line-height: 29px;
  width: 334px;
  text-align: left;
  text-transform: uppercase;
}

#rally-title {
  width: 75%;
}

.open-dropdown::before {
  height: 75%;
  margin-right: 3px;
  content: "+";
}

.close-dialog {
  color: #70B6BC;
  font-family: var(--dinmedium);
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1.1px;
  line-height: 14px;
  width: 350px;
  text-align: left;
  text-transform: uppercase;
  outline:none !important;
}

.close-dialog:focus {
  outline: 0 !important;
}

.ui-dialog input[type="text"] {
  width: 100% !important;
}

.modal-options {
  text-align: right;
  margin-bottom: -40px;
}

.modal-options .close-dialog {
  text-transform: uppercase;
  cursor: pointer;
  font-size: 12px;
  padding: 0px 20px;
  font-weight: normal;
  letter-spacing: .86px;
}

.modal-options .ki-btn {
  font-size: 12px !important;
  letter-spacing: 0.86px;

}

.close-window {
  outline:none !important;
}

.close-window:focus {
  outline:none !important;
}


a:link:active {
  color: #70B6BC;
}

a:visited:active {
  color: #70B6BC;
}

.form-element {
  margin-bottom: 20px;
}

.visit-heading {
  display: inline;
  width: 25%;
  margin-right: 5px;
}

.tc-table {
  width: 100%;
  list-style-type: none;
}

.tc-table-row {
  width: 100%;
  display: block;
}

.tc-table-row .dynamic-select-box {
  width: 100%;
  max-width: 100%;
}

.tc-col {
  /*display: table-cell;*/
  /*width: 20%;*/
  /*padding-right: 10px;*/
  box-sizing: border-box;
  display: inline-block;
}

.tc-table-header {
  margin-bottom: 0px;
}

.tc-table-header .tc-col {
  padding: 10px 0px 10px 10px;
  font-family: var(--dinregular);
  font-size: 14px;
  font-style: italic;
  color: #3d5052;

}

.tc-col input {
  padding-left: 10px;
  margin-bottom: 5px !important;
}

.tc-close-col {
  padding-left: 10px;
  width: 100%;
}

.tc-col-1 {
  width: 15%;
}

.tc-col-2 {
  width: 15%;
}

.tc-col-3 {
  width: 32%;
}

.tc-col-4 {
  width: 20%;
}


.tc-col-4 select {
  height: 44px;
  margin-bottom: 5px !important;
  padding: 0px 5px;
  height: 30px;
}

.tc-col-5 {
  width: 45px;
}


.people:nth-of-type(even){
  background-color: rgba(238,236,235,.4);
}


#upload_recording div {
  display: inline-block;
  margin-right: 8px;
}

#upload_presentation div {
  display: inline-block;
  margin-right: 8px;
}

#upload_analysis div {
  display: inline-block;
  margin-right: 8px;
}

#upload_recording:hover div svg g, #upload_analysis:hover div svg g, #upload_presentation:hover div svg g {
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

#upload_recording:hover div svg g {
  stroke:#fff !important;
}

#upload_presentation:hover div svg g {
  stroke:#fff !important;
}

#upload_analysis:hover div svg g {
  stroke:#fff !important;
}

.tt-dataset-0 {
  background-color: white;
  padding: 10px;
}

.token-label {
  outline: none;
  background: unset;
  background-color: #3D50521A !important;
  display: inline-block;
  text-transform: uppercase;
  color: #fff;
  text-decoration: none !important;
  padding: 0px 17px;
  border-radius: 30px;
  border: none;
  color: var(--darktext);
  font-family: var(--dinregular);
  font-size: 14px;
  letter-spacing: 1px;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  height: 34px;
  line-height: 32px;
  outline: none;
  margin: 2px;
}

.close {
  margin: 0 0 4px 4px;
  border: none;
  cursor: pointer;
  vertical-align: middle;
  font-family: var(--dinregular);
  color: #fff;
  background: #3D50524d;
  border-radius: 10px;
  display: inline-block;
  font-size: 22px;
  height: 18px;
  width: 18px;
  text-align: center;
  line-height: 12px;
}

.token-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 165px;
}

.token-label:hover {
  cursor: -webkit-grab;
  cursor: grab;
}

.tag-list:hover {
  cursor: -webkit-grab;
  cursor: grab;
}

.alert-danger {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}
.fade.in {
  opacity: 1;
}
.alert-success {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}
.alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}
.fade {
  opacity: 0;
  -webkit-transition: opacity .15s linear;
  -o-transition: opacity .15s linear;
  transition: opacity .15s linear;
}

.final-doc-category {
  text-transform: capitalize;
}

.locked {
  opacity:    0.9;
  background: #fff;
  width:      100%;
  height:     100%;
  z-index:    10;
  top:        0;
  left:       0;
  position:   fixed;
}

/*.top .ui-tooltip-content::after {*/
/*top: -10px;*/
/*left: 50%;*/
/*border-color: #4fb9c2 transparent;*/
/*border-width: 0 10px 10px;*/
/*margin-left: -10px;*/
/*color: white;*/
/*}*/

/*.top .ui-tooltip-content::before {*/
/*border-color: rgba(118, 118, 118, 0);*/
/*border-bottom-color: #4fb9c2;*/
/*top: -11px;*/
/*left: 50%;*/
/*border-width: 0 11px 11px;*/
/*margin-left: -11px;*/
/*color: white;*/
/*}*/

/*.arrow_box {*/
/*position: relative;*/
/*background: #88b7d5;*/
/*border: 4px solid #c2e1f5;*/
/*}*/
/*.arrow_box:after, .arrow_box:before {*/
/*bottom: 100%;*/
/*left: 50%;*/
/*border: solid transparent;*/
/*content: " ";*/
/*height: 0;*/
/*width: 0;*/
/*position: absolute;*/
/*pointer-events: none;*/
/*}*/

/*.arrow_box:after {*/
/*border-color: rgba(136, 183, 213, 0);*/
/*border-bottom-color: #88b7d5;*/
/*border-width: 30px;*/
/*margin-left: -30px;*/
/*}*/
/*.arrow_box:before {*/
/*border-color: rgba(194, 225, 245, 0);*/
/*border-bottom-color: #c2e1f5;*/
/*border-width: 36px;*/
/*margin-left: -36px;*/
/*}*/

/* #page-header.smaller {
    height: 75px;
}

#page-header.smaller .row{
    height: 75px;
    line-height: 26px;
    font-size: 26px;
    margin: 0;
}

#page-header.smaller nav a {
    line-height: 26px;
} */


.pi-wrap ul {
  width: 100%;
}


.pi-wrap ul select, .pi-wrap ul input {
  width: 43%;
  display: inline-block;
  vertical-align: middle;
  margin-right: 2%;
  height: 46px;
}



#matrix .token {
  display: inline-block;
  position: relative;
}

#matrix .token-label {
  padding-right: 30px;
  font-size: 11px;
}

#matrix .token a {
  position: absolute;
  right: 9px;
  top: 10px;
}

#matrix .heading-3 {
  text-transform: uppercase;
}

#matrix tbody th {
  vertical-align: bottom;
  padding-bottom: 30px;
}

#matrix table tbody > tr td {
  border-top:1px solid #ccc;
  border-bottom:0px solid #ccc !important;
}

#matrix td {
  vertical-align: bottom;
  border-bottom:1px solid #ccc;
  padding-bottom: 15px;
  padding-top: 20px !important;
}

.tt-dropdown-menu {
  font-family: var(--dinregular);
  color: var(--darktext);
  background-color: #fff;
}

.study-card {
  width: 23%;
  margin-left: 1%;
  margin-right: 1%;
  margin-bottom: 20px;
}

.profile-card {
  width: 23%;
  margin-left: 1%;
  margin-right: 1%;
  margin-bottom: 20px;
}

.rally-card {
  width: 31%;
  margin-left: 1%;
  margin-right: 1%;
  margin-bottom: 20px;
}

@media screen and (min-width: 1300px) {
  .rally-card, .study-card, .profile-card {
    width: 23%;
  }
}

@media screen and (max-width: 1470px) {
  .rally-card, .study-card, .profile-card {
    width: 31%;
  }
}


@media screen and (max-width:1130px) {
  .rally-card, .study-card, .profile-card {
    width: 47%;
  }
}

@media screen and (max-width:860px) {
  .rally-card, .study-card, .profile-card {
    width: 98%;
  }
}


/* Basic Layout */
#page-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.card-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  /* -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between; */
  -ms-flex-wrap:wrap;
  flex-wrap:wrap;
}

.card-tag {
  word-break: break-all;
}

header {
  height: 100%;
}

.sliding-menu {
  height: 100%;
  min-height: 100vh;
}

.left-column {
  width: 225px;
}

/* @media screen and (max-width: 1100px) {
  .left-column {
    width: 100px;
  }
} */

.right-column {
  padding: 50px;
  width: calc(100% - 225px);
  overflow-y: hidden;
  padding-top: 31px;
}

.study-edit .right-column {
  overflow-x: hidden;
  overflow-y: auto;
  overflow-y: auto;
}

/* @media screen and (max-width: 1100px) {
  .right-column {
    width: calc(100% - 100 Fpx);
  }
} */

.barStyle {
  height: 20px;
}

.view-element .dynamic-select-box {
  max-width: 25%;
}

.dynamic-select-box {
  margin: 10px;
  margin-left: 0px;
  outline: 0;
  text-align:left;
  input {
    padding-left: 0 !important;
  }
}

.gant-chart {
  height: 400px;
}

.person-wrap {
  display: flex;
  flex-wrap: nowrap;
}

.person-wrap .person-container {

}

.person-container {
  display: flex;
  flex-wrap: nowrap;
}

.person-container > div {
  width: 100px;
  margin: 10px;
  text-align: center;
  line-height: 75px;
  font-size: 30px;
}

.wrapper {
  height: 100%;
}

.test {
  border-color: black;
  border: solid 1px;
}

.person-heading-cell-1 {
  margin-right: 50%;
}

.person-heading-cell-2 {
  margin-left: 49%;
}

.person-heading-top-left {
  margin-right: 50%;
  text-align: left;
}

.person-heading-top-right {
  margin-left: 50%;
  text-align: right;
}

.nav-tabs { border-bottom: 2px solid #DDD; font-family: "Roboto Condensed",sans-serif; }
.nav-tabs > li.active > a, .nav-tabs > li.active > a:focus, .nav-tabs > li.active > a:hover { border-width: 0; font-family: "Roboto Condensed",sans-serif;}
.nav-tabs > li > a { border: none; color: #ABABAB; text-transform: uppercase; font-weight: bold; font-size: 15px;}
.nav-tabs > li.active > a, .nav-tabs > li > a:hover { border: none; color: #111 !important; background: transparent; }
.nav-tabs > li > a::after { content: ""; background: #4EB8C1; height: 2px; position: absolute; width: 100%; left: 0px; bottom: -1px; transition: all 250ms ease 0s; transform: scale(0); }
.nav-tabs > li.active > a::after, .nav-tabs > li:hover > a::after { transform: scale(1); }
.tab-nav > li > a::after { background: #4EB8C1 none repeat scroll 0% 0%; color: #fff; }
.tab-pane { padding: 15px 0; }
.tab-content{padding:20px}

.nav-tabs > li.active > a, .nav-tabs > li.active > a:focus {
  background-color: transparent !important;
}

.small-profile-info .small-profile-image {
  height: 56px;
  width: 56px;
  display: block;
  border-radius: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  margin-bottom: 15px;
}


.profile-info .profile-image,
.profile-card .profile-image {
  /* box-shadow: 0px 2px 3px 1px #d8d8d8;
  border:0px; */
}

.rally-card {
  /* width: 304px;
  min-height: 384px; */
}

.sidemenu-text {
  color: #FFFFFF !important;
  font-family: "Roboto Condensed",sans-serif;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 48px;
  opacity: 0.6;
  width: 143.45px;
  text-transform: uppercase;
  text-align: left;
}

.sidemenu-text:hover {
  opacity: .9;
  color: #FFFFFF !important;
}

.filter-bar .css-10nd86i {
  display: inline-block;
  width: 18%;
}

div.ReactTags__tags {
  position: relative;
}

/* Styles for the input */
div.ReactTags__tagInput {
  width: 200px;
  border-radius: 2px;
  display: inline-block;
}
div.ReactTags__tagInput input.ReactTags__tagInputField,
div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  height: 31px;
  margin: 0;
  font-size: 12px;
  width: 100%;
  border: 1px solid #eee;
  padding: 0 4px;
}

/* Styles for selected tags */
div.ReactTags__selected span.ReactTags__tag {
  border: 1px solid #ddd;
  background: #eee;
  font-size: 12px;
  display: inline-block;
  padding: 5px;
  margin: 0 5px;
  cursor: move;
  border-radius: 2px;
}
div.ReactTags__selected a.ReactTags__remove {
  color: #aaa;
  margin-left: 5px;
  cursor: pointer;
}

/* Styles for suggestions */
div.ReactTags__suggestions {
  position: absolute;
  z-index: 1001;
}
div.ReactTags__suggestions ul {
  list-style-type: none;
  box-shadow: .05em .01em .5em rgba(0,0,0,.2);
  background: white;
  width: 200px;
}
div.ReactTags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 5px 10px;
  margin: 0;
}
div.ReactTags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}
div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
  background: #b7cfe0;
  cursor: pointer;
}

.kifeed-news {
  padding: 10px;
  width: 40%;
}

.kifeed-right {
  padding: 10px;
}

.kifeed-study {
  max-height: 50%;
}

.kifeed-rally {
  max-height: 50%;
}



.feed-left-column {
  position: absolute;
  height: calc(100% - 115px);
  padding: 10px;
  margin-right: 60%;
  overflow: scroll;
}

.feed-left-column .feed-item:last-of-type {
  border-bottom: 0px;
}

.feed-right-column .feed-item:last-of-type {
  border-bottom: 0px;
}


.feed-right-column {
  position: absolute;
  margin-left: 42%;
  /* padding: 10px; */
  /* height: 984px; */
  height: calc(100% - 115px);
}


@media screen and (max-width: 1365px) {

  /* .ki-feeds {
    width: 100%;
  }

  .feeds-right-column,
  .feeds-left-column {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: calc(50% - 115px);
  } */

}


.feed-left-column.hub-content-wrapper,
.top-section.hub-content-wrapper,
.bottom-section.hub-content-wrapper
{
  padding: 25px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.top-section.hub-content-wrapper .feed-content,
.bottom-section.hub-content-wrapper .feed-content {
  height: 100%;
  overflow: scroll;
}

.bottom-section {
  position: relative;
  margin-top: 30px;
  overflow: scroll;
  /* height: 480px; */
  height: 48%;
}

.top-section {
  position: relative;
  margin-bottom: 30px;
  overflow: scroll;
  /* height: 480px; */
  height: 48%;
}

@media screen and (max-width: 1365px) {

  .top-section {
    height: 100%;
    margin-bottom: 30px;
  }

  .bottom-section {
    height: 100%;
    margin-top: 30px;
  }

  .ki-feed .content-wrapper {
    width: auto;
  }

  .feed-left-column,
  .feed-right-column {
    width: 88%;
    position: relative;
    margin-left: 0px;
    height: 30%;
  }

  .feed-left-column {
    margin-bottom: 30px;
  }

}


.content-wrapper {
  position: absolute;
  width: 1024px;
  height: calc(100% - 50px);
  padding: 10px;
}

pre {
  background: white;
  border: 0px;
}


.hub-content-wrapper {
  background-color: #fff;
}

.hub-content-wrapper .news-item:last-of-type {
  border-bottom:0px;
}

.news-feed-header {
  border-bottom: 1px solid #D8D8D8;
  padding-bottom: 20px;
}

.news-feed-header h2 {
  display: inline-block;
  margin-top: 5px;
}

.feed-header-left-col a {
  color: #111;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  font-family: "Roboto Condensed",sans-serif;
}

.news-item-left-col {
  padding-right: 10px;
}

/* Profile */

.profile-attribute {
  text-transform: capitalize;
}

.profile-email.profile-attribute {
  text-transform: none;
}

.profile-seperated {
  border-bottom: 2px solid #d8d8d8;
  margin-bottom: 30px;
  padding-bottom: 30px;
}

.profile-image-wrap {
  margin-top: 15px;
}

.attribute-spacing {
  margin-bottom: 30px;
}

.participant-in {
  margin-bottom: 30px;
}


/* toggles */

.toggle-view-wrap {
  float: right;
}

.card-toggle {
  border-radius: 8px 0px 0px 8px;
  background-color: #4EB8C1;
  height: 36px;
  border:0px;
  padding: 0px 10px;
  outline: none;
  border:1px solid #4EB8C1;
  border-right: 0px;
}

.list-toggle {
  border-radius: 0px 8px 8px 0px;
  background-color: rgba(255,255,255,.5);
  height: 36px;
  border:0px;
  padding: 0px 10px;
  outline: none;
  border:1px solid #D8D8D8;
}

.card-toggle svg {
  display: block;
}

.list-toggle svg {
  display: block;
}

.list-toggle:hover g {
  fill:#4c5660;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.card-toggle-list-view {
  border-radius: 8px 0px 0px 8px;
  background-color: rgba(255,255,255,.5);
  height: 36px;
  border:0px;
  padding: 0px 10px;
  outline: none;
  border:1px solid #D8D8D8;
  border-right: 0px;
}

.list-toggle-list-view {
  border-radius: 0px 8px 8px 0px;
  background-color: #4EB8C1;
  height: 36px;
  border:0px;
  padding: 0px 10px;
  outline: none;
  border:1px solid #4EB8C1;
}

.card-toggle-list-view svg {
  display: block;
}

.card-toggle-list-view svg g {
  fill:#8A95A1 !important;
}

.list-toggle-list-view svg {
  display: block;
}

.list-toggle-list-view svg  g {
  fill:#fff !important;
}

.card-toggle-list-view:hover g {
  fill:#4c5660 !important;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

ul {
  list-style-type: none;
}.sprint_question {
   position: relative;
   display: block;
   width: 415px;
 }

.sprint_question .profile-card {
  width: 405px;
}

.sprint_question .close-icon {
  position: absolute;
  top: 10px;
  right: 20px;
}

.sprint_question textarea {
  width: 100% !important;
  border-radius: 0px !important;
  border: 1px solid #D8D8D8;
  padding: 11px !important;
  height: auto !important;
  font-family: "Roboto",sans-serif;
}

#arms_form .arm_description {
  width: 100% !important;
  border-radius: 0px !important;
  border: 1px solid #D8D8D8;
  padding: 11px !important;
  height: auto !important;
  font-family: "Roboto",sans-serif;
}

#arms_form .form-repeat .heading-3 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.ReactTags__tags .ReactTags__tag {
  border-radius: 16px;
  font-size: 14px;
  font-family: "Roboto",sans-serif;
  font-weight: 700;
  color: #4A4A4A;
  border: 1px solid #D8D8D8;
  display: inline-block;
  padding: 5px 15px 4px;
  padding-left: 15px;
  background-color: #F1F3F6;
  cursor: pointer;
  position: relative;
}

.body-text .view-element {
  margin-bottom: 0px;
}

.tc-col-1 input,
.tc-col-2 input,
.tc-col-3 input {
  padding:13px !important;
}

.tc-col-4 {
  vertical-align: top;
}

.tc-col-4 .dynamic-select-box {
  margin-top: 0px;
}

.tc-col-5.close-icon {
  margin-right: 45px !important;
}


.sq-list2 {
  padding-left: 0px;
}

.sq-list2 .sprint_question {
  margin-bottom: 10px;
}

.rally-header-btn .right-align-icon,
.rally-next-icon .right-align-icon,
.card-button-row .right-align-icon,
.data-link .right-align-icon,
.hero-heading-col .right-align-icon {
  position: absolute;
  margin-top: 2px;
  margin-left: 6px;
}

.plus-wrap {
  position: absolute;
  margin-top: 1px;
  width: 12px;
  height: 12px;
  display: block;
  margin-left: -17px;
}

.plus-wrap svg {
  fill:#4EB8C3;
}

/* a.rally-header-btn:hover svg g {
  fill:#fff;
  stroke:#fff;
  transition: all 360ms;
  -webkit-transition: background 360ms;
  -moz-transition: background 360ms;
  -o-transition: background 360ms;
  transition: background 360ms;
} */

a.label:focus, a.label:hover {
  color: #4EB8C1;
}


.filter-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
}

.filter-bar .search-input {
  margin: 10px 0;
}

.feedhero {
  margin-bottom: 14px;
}

.hero-heading-wrapper {
  border-bottom: 2px solid #D8D8D8;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
  padding-bottom: 23px;
}

.studies-heading-wrapper,
.rallies-heading-wrapper {
  border-bottom:0px;
}

.hero-heading-col {
  width: 50%;
}

.hero-heading-col:last-of-type {
  text-align: right;
}


.sq-list1 {
  padding-left: 0px;
}

.sq-list1 .sprint_question:first-of-type::before {
  content: "";
  display: block;
  position: absolute;
  background-color: #D8D8D8;
  width: 100%;
  height: 2px;
  margin-top: -24px;
}

.news-item {
  padding: 20px 24px 15px !important;
  border-bottom: 2px solid #f1f3f6 !important;
}

.feed-info {
  text-transform: capitalize;
}


.button-text-icon.rally-header-btn:hover, .rally-next-icon .button-text-icon:hover, .hero-heading-col .button-text-icon:hover {
  background-color: #4EB8C1;
  color: #fff;
  border: 1px solid #4eb8c1;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.button-text-icon.rally-header-btn:hover g, .rally-next-icon .button-text-icon:hover g, .hero-heading-col .button-text-icon:hover g, .hero-heading-col .button-text-icon:hover .plus-wrap svg, .hero-heading-col .button-text-icon:hover .edit-wrap svg g {
  fill:#fff !important;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.hero-heading-col .button-text-icon:hover g {
  fill:none;
}

.button-text-icon.rally-header-btn {
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.button-text-icon.rally-header-btn g {
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.card-button-row .button-text-icon:hover .right-align-icon g {
  /* fill:#4EB8C1; */
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}


.card-button-row .button-text-icon {
  margin-right: 10px;
}

.rally-next-icon button {
  padding-top: 6px;
  padding-bottom: 7px;
  outline:0;
}


.hub-table-program {
  padding: 15px 10px 15px 20px !important;
}

.hub-table-controls {
  padding: 15px 20px 15px 10px !important;
}

.button-text-icon.icon-left:before {
  top:9px !important;
}

.last-edited-news-item {
  margin-top: -15px;
  margin-bottom: 15px;
}

.submit-button:hover {
  color: #fff;
  background-color: #4e94c1 !important;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.hub-table-study {
  font-weight: normal !important
}

.hub-table-study a {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
}

.hub-table-program {
  font-weight: 700;
}


.data-link:hover {
  background-color: #a5aaaf;
  border: 1px solid #a5aaaf;
  color: #3a4651;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.data-link g {
  fill: #3a4651;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.data-link:focus {
  background-color: #4c5660;
  border: 1px solid #4c5660;
  color: #fff !important;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.data-link:focus g {
  fill:#fff !important;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.edit-button {
  /* background-color: #4EB8C1 !important;
  color: #fff !important;
  border: 1px solid #4EB8C1 !important; */

}

.edit-button svg {
  fill: #fff !important;
}

.rally-header-btn.edit-button svg {
  fill: #4A4A4A !important;
}


.edit-button:hover path {
  fill: #fff !important;
}

.edit-button:hover {
  background-color: #4C5660 !important;
  border: 1px solid #4C5660 !important;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.edit-button:focus {
  background-color: #4c5660 !important;
  border: 1px solid #4c5660 !important;
  color: #fff !important;

}

.edit-button:focus path {
  fill:#fff !important;
}

.active {
  opacity: .9;
}



.login-header {
  width:100%;
  background:#000;
  /* height:50px; */
  padding:10px;
  box-sizing:border-box;
}
.login{
  background:#fff;
  width:100%;
  /* padding:10px; */
  height:100%;
  box-sizing:border-box;
}
.left{
  width:50%;
  float:left;
  background:#F1F3F6;
  height:100%;
}
.right{
  width:50%;
  float:right;
  background:#fff;
  height:100%;
}

.rectangle-2 {
  background-color: #D8D8D8;
  background: url("../images/home_img.png");
  width: 460px;
  height: 256px;
}
.edit-icon-only {
  background-color: #F1F3F6 !important;
  border: 1px solid #D6DADF !important;
}

.edit-icon-only svg {
  fill:#3a4652 !important;
}

.edit-icon-only:hover {
  background-color: #a5aaaf !important;
  border: 1px solid #a5aaaf !important;
}

.edit-icon-only:hover svg, .edit-icon-only:hover path {
  fill:#3a4652 !important;
}

.edit-icon-only:focus svg, .edit-icon-only:focus path {
  fill:#fff !important;
}

.edit-icon-only:focus {
  background-color: #4c5660 !important;
}

.backlink {
  display: inline-block;
  width: 15px;
  height: 20px;
  background-image: url(/images/back-arrow.svg);
  background-position: 50%;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 5px;
  margin-left: -5px;
  opacity: .3;
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -ms-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}

.backlink:hover {
  opacity: 1;
}

form .submit-button {
  margin-right: 10px !important;
  border-radius: 24px !important;
  padding: 7px 30px !important;
}

.login-header {
  background-color: #1D2C3B;
  display: block;
  width: 100%;
  position: absolute;
  padding: 10px;
}


body.news-item {
  padding: 0px !important;
}


body.news-item #page-wrapper,
body.rally #page-wrapper,
body.study #page-wrapper,
body.person #page-wrapper,
body.rally-edit #page-wrapper,
body.study-edit #page-wrapper,
body.people-edit #page-wrapper  {
  background-color: #fff !important;
}


body.rally-edit .view-element,
body.study-edit .view-element,
body.study-edit .form-question {
  border-bottom: 1px solid #eef2f5;
  padding-bottom: 50px;
  max-width: 100%;
}

body.rally-edit .view-element input,
body.study-edit .view-element input {
  max-width: 460px;
}

body.rally-edit label,
body.study-edit label,
body.news-edit label {
  font-weight: 700;
  color: #131D27;
  text-transform: capitalize;
}


.body-text .view-element {
  border-bottom: 0px !important;
  padding-bottom: 0px !important;
}


.login #root,
.login .App,
.login #page-wrapper,
.login .Login {
  height: 100%;
}

.login .Login .left,
.login .Login .right {
  padding: 100px 30px 0px 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center;
  -webkit-box-pack:center;
  -ms-flex-pack:center;
  justify-content:center;
}

.login .left-column {
  width: auto;
}

.login .right-column {
  width: 100%;
  padding: 0px;
}

.login-right-wrap h1 {
  margin-bottom: 30px;
}

.login-right-wrap input {
  margin-bottom: 20px;
}

.login-right-wrap label a {
  float: right;
}


.chart-wrap {
  padding: 30px 20px;
  background-color: #fff;
}

.chart-wrap td div {
  /* height: 20px !important; */
}

.last-updated-box {
  float: right;
  margin-right: 0px;
}

.last-updated-box .subtext {
  line-height: 26px;
}

.button-controls {
  text-align: right;
}

.study-edit .button-controls {
  padding-right: 0px;
}

#tab-0 {
  margin-top: 15px;
}

.study #tab-0 {
  margin-top: 5px;
}

.study .last-updated-box {
  margin-top: 9px;
}

.rally .last-updated-box {
  margin-bottom: -1px;
}

.header-hr {
  border-top:2px solid #DDD !important;
  margin-right: -15px;
}

.rally-edit-header {
  margin-right: 0px;
}

.rally-edit-header .button-controls {
  padding-right: 0px;
  display:flex;
  flex-direction: row;
  justify-content: space-between;
}

.rally-edit-header .button-controls .dynamic-select-box {
  flex: 1;
}

.rally-edit-header .last-updated-box .subtext {
  margin-bottom: -25px;
}

.rally-edit-header .button-controls .button-text-icon {
  margin: 0px !important;
  align-self:center;
}


.small-profile-info {
  display: inline-block;
}

#personal-menu-reveal {
  display: inline-block;
  cursor: pointer;
  width: 14px;
  height: 7px;
  margin-top: -55px;
  display: block;
  margin-left: 70px;
  -ms-transform: rotate(-90deg); /* IE 9 */
  -webkit-transform: rotate(-90deg); /* Safari 3-8 */
  transform: rotate(-90deg);
  background-image: url(/images/profile-triangle.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.personal-menu-revealed {
  background-image: url(/images/profile-triangle.svg);
  -ms-transform: rotate(0deg) !important; /* IE 9 */
  -webkit-transform: rotate(0deg) !important; /* Safari 3-8 */
  transform: rotate(0deg) !important;
}

.personal-menu .menu-item {
  height: 20px;
  padding: 0px;
  margin-left: 70px;
  margin-bottom: 6px;
}


.forgot-pass #root,
.forgot-pass .App,
.forgot-pass #page-wrapper {
  height: 100%;
}

.forgot-pass .right-column {
  margin-top: 80px;
}


.forgot-pass .button-controls {
  margin-top: 10px;
  text-align: left;
}

.forgot-pass .button-controls .submit-button, .forgot-pass .button-controls .button-text-icon {
  margin-right: 10px !important;
  border-radius: 24px !important;
  padding: 7px 30px !important;
}

.participants-box ul li .dynamic-select-box {
  display: inline-block;
  min-width: 250px;
}


#profile_pic_submit {
  border-radius: 16px !important;
  font-size: 14px;
  font-family: "Roboto",sans-serif;
  font-weight: 700;
  color: #4A4A4A;
  border: 1px solid #D8D8D8;
  display: inline-block;
  padding: 5px 15px 4px !important;
  background-color: #F1F3F6;
  cursor: pointer;
  position: relative;
  margin-top: 10px;
}

.upload_form input {
  font-family: 'roboto';
  font-size: 14px;
  cursor: pointer;
}

.form-repeat {
  max-width: 51%;
}
