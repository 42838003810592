.view-element {
  border-bottom: 1px solid #E8EDF1;
}

.input-form {
  input, textarea {
    width: 100%;
    border: 1px solid #d8d8d8;
    padding: 11px !important;
    font-family: "Roboto", sans-serif;
    background-color: #fff;
    color: #4a4a4a;
    outline: none;
    border-radius: 0 !important;
    max-width: 550px;
  }
  textarea {
    min-height: 100px;
    height: auto;
  }
  .date-range {
    display: flex;
    justify-content: space-between;
    max-width: 460px;
  }
  .data {
    ul {
      padding: 0;
    }
  }
  .ant-tag, .ant-input, .ant-select-dropdown-menu-item {
    font-family: "Roboto", sans-serif;
    font-size: 14px;

  }
}

.caption-text {
  span {
    display: block;
  }
}

.single-dropdown {
  display: flex;
  flex: 1;
  .dynamic-select-box {
    text-transform: capitalize;
  }
}

.repeater-field {
  display: flex;
  max-width: 460px;
  align-items: center;
  justify-content: space-between;
  textarea {
    margin: 10px 0;
  }
}

.close-icn {
  margin-left: 15px;
  transform: rotate(0deg);
  svg {
    circle {
      fill: #ffffff;
      transition: all 300ms ease-in-out;
    }
    path {
      stroke: #4EB8C1;
      transition: all 300ms ease-in-out;
    }
  }
  &:hover {
    cursor: pointer;
    svg {
      circle { fill: #4EB8C1; transition: all 300ms ease-in-out; }
      path { stroke: #ffffff; transition: all 300ms ease-in-out; }
    }
  }
}

.repeater-card {
  width: 460px;
  margin-bottom: 15px;
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 15px;
  }
  .card-content {
    label {
      font-weight: normal;
    }
    .single-dropdown {
      width: 250px;
      display: block;
      .dynamic-select-box {
        max-width: 100%;
      }
    }
  }
}

.file-upload-header,
.file-upload-content {
  display: flex;
  .view-element {
    border: none !important;
    padding: 0 !important;
    max-width: none !important;
    margin: 0 !important;
    label {
      margin: 0 !important;
      display: none;
    }
  }
}

.file-upload-header {
  justify-content: space-between;
  align-items: flex-start;
}

.file-upload-content {
  align-items: center;
  .link-button {
    margin: 0 !important;
  }
}

.single-upload {
  .upload-btn {
    background: #ffffff;
    color: #4EB8C1;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16), 0 0 1px 0 rgba(0, 0, 0, 0.12);
    border: 0;
    margin-right: 15px;
    margin-bottom: 0;
  }
  input {
    display: none;
  }
}

.loading-icon {
  height: 20px;
}

.two-dropdown {
  display: flex;
  .single-dropdown {
    width: 250px;
    display: block;
    .dynamic-select-box {
      max-width: 100%;
    }
  }
}

.create-edit {
  margin-bottom: 40px;
}

.form-wrap {
  .view-element {
    border: none;
  }
}

.free-floating-dropdown {
  min-width: 250px;
  .view-element {
    border: none;
  }
  .single-dropdown {
    display: block;
  }
}

.disabled {
  opacity: 0.4;
  &:hover {
    background-color: #F1F3F6 !important;
    color: #4A4A4A !important;
    border: 1px solid #D8D8D8 !important;
  }
}

.delete-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  .delete-modal-content {
    width: 550px;
    background: $light-blue;
    padding: 30px;
    border-radius: 5px;
    label {
      font-family: "Roboto Condensed", sans-serif;
      font-size: 18px;
    }
    .buttons {
      display: flex;
      justify-content: flex-end;
      margin-top: 40px;
      button {
        background: #ffffff;
        &.primary-btn {
          margin-left: 10px;
          background: #4EB8C1;
          color: #ffffff;
        }
      }
    }
  }
}

.footer-buttons {
  display: flex;
  justify-content: space-between;
}

.error {
  display: flex;
  align-items: center;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  color: #F65C43 !important;
  margin-top: 10px;
  svg {
    margin-right: 5px;
  }
}

.position-arrow {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px;
  svg {
    margin: 5px 0;
    &.inactive {
      path {
        fill: #dedede;
      }
    }
    &.active {
      cursor: pointer;
      &:hover {
        path {
          fill: #4EB8C1;
        }
      }
    }
  }
}

.import-rally {
  width: 460px;
  max-width: 460px !important;
}

.checkbox-group {
  padding: 10px 0 0 0;
  display: flex;
  height: 200px;
  flex-direction: column;
  flex-wrap: wrap;
  .import-checkbox {
    font-family: 'Roboto' !important;
    font-size: 14px !important;
    margin: 10px 0;
    .ant-checkbox-inner {
      height: 20px;
      width: 20px;
      border-color: #dadada;
      &::after {
        height: 12px;
        width: 7px;
        top: 48% !important;
      }
    }
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #4EB8C1;
      border-color: #4EB8C1;
    }
  }
}