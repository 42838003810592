.interior-header {
  // background: $light-blue;
  .row {
    margin-top: 0;
  }
  .header-title {
    display: flex;
    height: 60px;
    h1 { font-weight: 700; }
    p { margin-left: 15px; }
  }
  &.with-border {
    border-bottom: 1px solid #C0C7CD;
  }
}

.tab-header {
  margin-top: 0 !important;
}

.tab-view {
  .right-column {
    height: 100vh;
    padding-bottom: 0;
    position: relative;
    .right-column-content {
      position: absolute;
      top: 31px;
      right: 50px;
      left: 50px;
      bottom: 0;
    }
    .tab-header {
      position: relative;
      height: 100%;
      .tab-content {
        position: absolute;
        top: 42px;
        right: 0;
        left: 0;
        bottom: 61px;
        overflow-y: auto;
      }
    }
  }
}

.main-logo {
  height: 25px;
  width: auto;
}

.login-header {
  .main-logo {
    height: 30px;
    margin: 8px;
  }
}