.gantt {
  margin: 15px 0;
  width: 100%;
  max-height: 500px;
  position: relative;
  overflow: auto;
  background: #FFFFFF;
  border: 1px solid #D6DADF;
  svg {
    display: block;
  }
  .gantt-bar {
    rect { opacity: 1; }
    &:hover {
      cursor: pointer;
      rect { opacity: 0.8; }
    }
  }
  .x-axis {
    text-transform: uppercase;
    text {
      fill: #4a4a4a;
    }
  }
}

#gantt-data {
  .yellow {
    fill: $yellow;
  }
  .blue {
    fill: $blue;
  }
}